// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { Container, Row, Col } from "react-bootstrap";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { setAuthentication, clearAuthentication, isLogin } from "../../utils/auth";
// import { baseURL } from "../../utils/constant";

// export default function Login({ setisAuthenticated }) {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [passwordVisible, setPasswordVisible] = useState(false);

//   useEffect(() => {
//     const authenticate = async () => {
//       if (await isLogin()) {
//         navigate("/account/dashboard");
//       }
//     };
//     authenticate();
//   }, [navigate]);

//   useEffect(() => {
//     const tokenInterval = setInterval(() => {
//       const loginTime = localStorage.getItem("loginTime");
//       const currentTime = new Date().getTime();
//       if (loginTime && currentTime - loginTime >= 20 * 60 * 1000) { 
//         clearAuthentication();
//         setisAuthenticated(false);
//         navigate("/login");
//         toast("Session expired. Please log in again.", {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       }
//     }, 60 * 1000); 

//     return () => clearInterval(tokenInterval); 
//   }, [navigate, setisAuthenticated]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "email") {
//       setEmail(value);
//     } else if (name === "password") {
//       setPassword(value);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const payload = { email, password };

//     axios
//       .post(`${baseURL}auth/login`, payload)
//       .then((res) => {
//         setAuthentication(res.data.bearerToken);
//         localStorage.setItem("token", res.data.bearerToken);
//         localStorage.setItem("isSignedIn", "true");
//         localStorage.setItem("loginTime", new Date().getTime());
//         setisAuthenticated(true);
//         navigate("/account/dashboard");
//         toast(res.data.message, {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       })
//       .catch((err) => {
//         toast(err?.response?.data?.message, {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       });
//   };

//   const handlePasswordToggle = () => {
//     setPasswordVisible(!passwordVisible);
//   };

//   return (
//     <div className="d-flex align-items-center bg-login bg-white">
//       <Container fluid>
//         <Row>
//           <Col lg={4} className="bg-white vh-100 d-flex align-items-center mx-auto">
//             <Col lg={10} className="p-5 bg-white border rounded-3 mx-auto">
//               <h4 className="fw-bold display-6 mb-4">Sign in</h4>

//               <Form onSubmit={handleSubmit}>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     className="rounded-3 form-control-sm"
//                     name="email"
//                     value={email}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>

//                 <Form.Group className="mb-2 position-relative">
//                   <Form.Label>Password</Form.Label>
//                   <div className="position-relative">
//                     <Form.Control
//                       type={passwordVisible ? "text" : "password"}
//                       className="rounded-3 form-control-sm"
//                       name="password"
//                       value={password}
//                       onChange={handleInputChange}
//                     />
//                     {password && (
//                       <button
//                         type="button"
//                         className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent"
//                         onClick={handlePasswordToggle}
//                       >
//                         {passwordVisible ? <FaEyeSlash /> : <FaEye />}
//                       </button>
//                     )}
//                   </div>
//                 </Form.Group>

//                 <div className="d-flex justify-content-end mb-3">
//                   <Link className="Forgotlink text-decoration-none" to="forgotpassword">
//                     Forgot password?
//                   </Link>
//                 </div>
//                 <div className="d-grid gap-2 mb-4">
//                   <Button
//                     variant="btn btn-orange"
//                     type="submit"
//                     className="rounded-3"
//                   >
//                     Continue
//                   </Button>
//                 </div>
//               </Form>

//               <p className="text-center">
//                 Don't have an account?{" "}
//                 <Link to="/register" className="text-decoration-none">
//                   Sign up
//                 </Link>
//               </p>
//             </Col>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setAuthentication, clearAuthentication, isLogin } from "../../utils/auth";
import { baseURL } from "../../utils/constant";

export default function Login({ setisAuthenticated }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin()) {
        navigate("/account/dashboard");
      }
    };
    authenticate();
  }, [navigate]);

  useEffect(() => {
    const tokenInterval = setInterval(() => {
      const loginTime = localStorage.getItem("loginTime");
      const currentTime = new Date().getTime();
      if (loginTime && currentTime - loginTime >= 20 * 60 * 1000) { 
        clearAuthentication();
        setisAuthenticated(false);
        navigate("/login");
        toast("Session expired. Please log in again.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }, 60 * 1000); 

    return () => clearInterval(tokenInterval); 
  }, [navigate, setisAuthenticated]);

  useEffect(() => {
    // Autofill check
    const emailField = document.querySelector('input[name="email"]');
    const passwordField = document.querySelector('input[name="password"]');
    if (emailField && passwordField) {
      setEmail(emailField.value);
      setPassword(passwordField.value);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { email, password };

    axios
      .post(`${baseURL}auth/login`, payload)
      .then((res) => {
        setAuthentication(res.data.bearerToken);
        localStorage.setItem("token", res.data.bearerToken);
        localStorage.setItem("isSignedIn", "true");
        localStorage.setItem("loginTime", new Date().getTime());
        setisAuthenticated(true);
        navigate("/account/dashboard");
        toast(res.data.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        toast(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="d-flex align-items-center bg-login bg-white">
      <Container fluid>
        <Row>
          <Col lg={4} className="bg-white vh-100 d-flex align-items-center mx-auto">
            <Col lg={10} className="p-5 bg-white border rounded-3 mx-auto">
              <h4 className="fw-bold display-6 mb-4">Sign in</h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    className="rounded-3 form-control-sm"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group className="mb-2 position-relative">
                  <Form.Label>Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={passwordVisible ? "text" : "password"}
                      className="rounded-3 form-control-sm"
                      name="password"
                      value={password}
                      onChange={handleInputChange}F
                    />
                    <button
                      type="button"
                      className="position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent"
                      onClick={handlePasswordToggle}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </Form.Group>

                <div className="d-flex justify-content-end mb-3">
                  <Link className="Forgotlink text-decoration-none" to="forgotpassword">
                    Forgot password?
                  </Link>
                </div>
                <div className="d-grid gap-2 mb-4">
                  <Button
                    variant="btn btn-orange"
                    type="submit"
                    className="rounded-3"
                  >
                    Continue
                  </Button>
                </div>
              </Form>

              <p className="text-center">
                Don't have an account?{" "}
                <Link to="/register" className="text-decoration-none">
                  Sign up
                </Link>
              </p>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

